<template>
  <b-card
    :title="title"
  >
    <div
      v-for="item in itemsSorted"
      :key="item.id"
      class="mb-1"
    >
      <b-card-text class="mb-50">
        {{ item.name }} {{ (typeof item.abbreviation !== 'undefined' && item.name !== item.abbreviation ? '(' + item.abbreviation + ')' : '') }} :
        <strong>{{ numberFormat(item.total_weight) }} {{ $t('units.kg') }}</strong> /
        <strong>{{ numberFormat(item.quantity) }} {{ $t('units.package') }}</strong>
      </b-card-text>
      <b-progress
        :value="item.total_weight"
        :max="maxQuantity"
        height="12px"
        :variant="variant"
        :animated="animated"
      />
    </div>
  </b-card>

</template>

<script>
import { BCard, BCardText, BProgress } from 'bootstrap-vue'

export default {
  components: {
    BProgress,
    BCard,
    BCardText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
    maxQuantity: {
      type: Number,
      required: true,
    },
    variant: {
      type: [String, undefined],
      default: undefined,
    },
    animated: {
      type: [Boolean, undefined],
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    itemsSorted() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.items.sort((a, b) => ((b.total_weight > a.total_weight) ? 1 : -1))
    },
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>
